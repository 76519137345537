<template>
  <v-container>
    <v-row justify="space-around">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
        transition="dialog-top-transition"
      >
        <v-card>
          <v-toolbar color="primary" dark>Reporte de Contravales</v-toolbar>
          <v-progress-linear
            v-if="loading"
            loading="loading"
            indeterminate
            color="teal"
          ></v-progress-linear>
          <div class="text-h2 pa-8">
            <v-form ref="myform">
              <v-row>
                <v-col cols="12" md="12">
                  <company-field
                    v-model="body.branch_id"
                    @value="(val) => (companyId = val)"
                    required
                  ></company-field>
                </v-col>
                <v-col cols="12" md="12">
                  <provider-field
                    :available="true"
                    v-model="body.provider"
                    required
                  ></provider-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-checkbox
                    v-model="check"
                    label="Mostrar número de vale"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="6">
                  <date-field
                    label="Del"
                    v-model="body.start_date"
                    required
                  ></date-field>
                </v-col>
                <v-col cols="12" md="6">
                  <date-field
                    label="Al"
                    v-model="body.end_date"
                    required
                  ></date-field>
                </v-col>
              </v-row>
            </v-form>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close"> Cerrar </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :disabled="!valid"
              @click="getPDF()"
            >
              Generar Reporte
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import requests from "@/services/requests";
import blobResponse from "@/utils/response.blob";
export default {
  components: {
    CompanyField: () => import("@/components/fields/CompanyField"),
    ProviderField: () => import("@/components/fields/ProviderField"),
    DateField: () => import("@/components/fields/DateField.vue"),
  },
  props: {
    dialog: { type: Boolean, required: true },
  },
  data() {
    return {
      companyId: undefined,
      body: {
        end_date: undefined,
        start_date: undefined,
        branch_id: undefined,
        provider: undefined,
      },
      check: false,
      valid: false,
      branches: [],
      loading: false,
      isDateRangeValid: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    resetForm() {
      (this.body = {
        end_date: undefined,
        start_date: undefined,
        branch_id: undefined,
        provider: undefined,
      }),
        this.$refs.myform.reset();
      this.valid = false;
      this.isDateRangeValid = false;
    },
    getPDF() {
      this.loading = true;
      this.valid = false;
      if (this.$refs.myform.validate()) {
        var api =
          "api/pdf/voucher-summary/?start=" +
          this.body.start_date +
          "&end=" +
          this.body.end_date +
          "&provider=" +
          this.body.provider.id +
          "&company=" +
          this.body.branch_id;
        if (this.check) {
          api += "&check=" + this.check;
        }
        requests.get(api).then((response) => {
          if (response.status == 200) {
            blobResponse(response.data, "application/pdf");
            this.resetForm();
            this.$emit("close");
            this.$toasted.global.info({ message: "El reporte fue generado" });
          } else {
            this.valid = true;
            this.$toasted.global.error();
          }
          this.loading = false;
        });
      }
    },
  },
  watch: {
    body: {
      handler: function (value) {
        if (value.start_date != undefined) {
          if (
            value.start_date.length === 10 &&
            value.end_date != undefined &&
            value.end_date.length === 10 &&
            value.branch_id != undefined
          ) {
            if (
              new Date(value.start_date).getTime() <=
              new Date(value.end_date).getTime()
            ) {
              this.valid = true;
            } else {
              this.valid = false;
              alert("El rango de fecha no es valido");
              this.body.end_date = "";
            }
          }
        }
      },
      deep: true,
    },
    companyId(val) {
      if (typeof val === "object") {
        this.body.branch_id = this.companyId.id;
      }
    },
  },
};
</script>